import Styled from 'styled-components';

export default function TechniqueBuilding(){
    return(
        <StyledTechniqueBuilding>
            <div className="contenTechnique">
                <div className="containerInfo">
                    <div className="contentInfo">
                        <h3 className='titleBuilding'><strong>COSTO TOTAL</strong></h3>
                        <h4 className='subTitleBuilding'>ENCOFRADO + MANO DE OBRA</h4>
                        <h4 className='subTitleBuilding'>5 EDIFICIOS DE 15 PISOS</h4>
                        <p>SUPERFICIE DE PISO DEL EDIFICIO: 300M2</p>
                        <p>ENCOFRADO POR PISO: 900M2</p>
                    </div>
                </div>
                <div className="containerInfo">

                    <div className="containerGraphip">
                        
                        <p className="">Alusystem</p>
                        <div class="graphic">
                            <div class="alusystem"></div>
                        </div>
                        
                        <p className="">Flex</p>
                        <div class="graphic">
                            <div class="flex"></div>
                        </div>

                        <p className="">Tradicional</p>
                        <div class="graphic">
                            <div class="tradicional"></div>
                        </div>

                    </div>
            
                    <div className="graphicReferences">
                        <ul className='listPrice'>
                            <li><span style={{color:'#f05a1a'}}>511.200 USD</span></li>
                            <li><span style={{color:'#a1a1a1'}}>1.297.800 USD</span></li>
                            <li><span style={{color:'#676767'}}>3.200.000 USD</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </StyledTechniqueBuilding>
    )
}

const StyledTechniqueBuilding = Styled.div`
    .contenTechnique{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-content: center;
        align-items: center;
        background-color: var(--colorFondoPrincipal);
        @media (min-width: 1200px){
            grid-template-columns: repeat(2, 1fr);
        }
    }
    .containerInfo{
        padding: 2rem;
        @media (min-width: 540px){
            padding: 2rem;
        }
        @media(min-width: 768px){
            width:100%;
            padding: 2rem;
        } 
    }
    .containerGraphip{
        margin: 0 auto;
        @media(min-width: 768px){
            width: 100%;
        }
    }
    .titleBuilding{
        font-size: 2rem;
        @media(min-width: 768px){
            font-size: 3.2rem;
            text-align: start:
        }
    }
    .subTitleBuilding{
        font-size: 2rem;
    }
    .contenGraphic{
        padding: 4rem;
        background-color: var(--colorFondoSecundario);
    }
    .graphic{
        display: block;
        width: 100%;
        height: var(--alturaBarra);
        background: #fff;
        box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
        margin-bottom: 14px;
    }
    .alusystem{
        display: block;
        height: var(--alturaBarra);
        background: #f05a1a;
        width: 15%;
    }
    .flex{
        display: block;
        height: var(--alturaBarra);
        background: var(--colorGrisSecundario);
        width: 40%;
    }
    .tradicional{
        display: block;
        height: var(--alturaBarra);
        background: var(--colorGRisTerciario);
        width: 100%;
    }
    .listPrice{
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
            @media(min-width: 540px){
                display:flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
            @media(min-width: 768px){
                flex-wrap: nowrap;
            }
            li{
                font-size: 1.79rem;
                text-align: center;
                padding: 1rem;
                white-space: nowrap
            }
        }
`