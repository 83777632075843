import Styled from "styled-components";
import Banner from "../../component/banner/Banner";
import Products from "../../component/products/Products";

export default function SystemView(props){
    return(
        <StyledSystemView>
            <Banner banner={banner}/>
            <div className="container">
                <h2>Losas</h2>
                <div className="row">
                    <div className="col-md-12 col-lg-4">
                        <div className="containerImg">
                            <img src="../../assets/img/system/losa/losa.jpg" alt="Armado losa con Alusystem"></img>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-8">
                       <Products product={productLosa}/>
                    </div>
                </div>
            </div>

            <div className="fondMain">
                <div className="container">
                    <h2>Muros</h2>
                    <div className="row">
                        <div className={window.innerWidth < 992 ? "mobile" : "desktop"}>
                            <div className="col-md-12 col-lg-8">
                            <Products product={productoMuro}/>
                            </div>
                            <div className="col-md-12 col-lg-4">
                                <div className="containerImg">
                                    <img src="../../assets/img/system/muro/muros.jpg" alt="Armado muros con Alusystem"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <h2>Escaleras</h2>
                <div className="row">
                    <div className="col-md-12 col-lg-4">
                        <div className="containerImg">
                            <img src="../../assets/img/system/escalera/escaleras.jpg" alt="Armado escaleras con Alusystem"></img>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-8">
                       <Products product={productoEscalera}/>
                    </div>
                </div>
            </div>

            <div className="fondMain">
                <div className="container">
                    <h2>Seguridad</h2>
                    <div className="row">
                        <div className={window.innerWidth < 992 ? "mobile" : "desktop"}>
                            <div className="col-md-12 col-lg-8">
                            <Products product={productoSeguridad}/>
                            </div>
                            <div className="col-md-12 col-lg-4">
                                <div className="containerImg">
                                    <img src="../../assets/img/system/seguridad/seguridad.jpg" alt="Elementos de Seguridad Alusystem"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <h2>Accesorios</h2>
                <div className="row" >
                    <div className="col-md-12 col-lg-4">
                        <div className="containerImg" style={{ marginBottom: '6rem'}}>
                            <img src="../../assets/img/system/accesorio/accesorios.jpg" alt="Accesorios de Alusystem"></img>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-8">
                       <Products product={productoAccesorios}/>
                    </div>
                </div>
            </div>

        </StyledSystemView>
    )
}

const banner = 
[
    {id: 1, title: 'Sistema', img: '../../../assets/img/project/skyscraper/bannerSkyscraper.jpg', alt: 'Imagen Banner' },
];

const productLosa =
[
    {id:1, title:'35000000 SLAB PANEL', img: '../../../assets/img/system/losa/35000000-SLAB-PANEL.jpg', alt:'35000000 SLAB PANEL'},
    {id:2, title:'35100000 SC', img: '../../../assets/img/system/losa/35100000-SC.jpg', alt:'35100000 SC'},
    {id:3, title:'35150000 Special PROP HEAD_3.15kg', img: '../../../assets/img/system/losa/35150000-Special-PROP-HEAD_3.15kg.jpg', alt:'35150000 Special PROP HEAD_3.15kg'},
    {id:4, title:'35160000 MIDDLE BEAM', img: '../../../assets/img/system/losa/35160000-MIDDLE-BEAM.jpg', alt:'35160000 MIDDLE BEAM'},
    {id:5, title:'35210000 SLAB Incorner_4.6kg', img: '../../../assets/img/system/losa/35210000-SLAB-Incorner_4.6kg.jpg', alt:'35210000 SLAB Incorner_4.6kg'},
    {id:6, title:'34500000 Slab lifting box_24.19kg', img: '../../../assets/img/system/losa/34500000-Slab-lifting-box_24.19kg.jpg', alt:'34500000 Slab lifting box_24.19kg'},
    {id:7, title:'35140000 PROP HEAD', img: '../../../assets/img/system/losa/35140000-PROP-HEAD.jpg', alt:'35140000 PROP HEAD'},
    {id:8, title:'35220000 SLAB Outcorner_5.5kg', img: '../../../assets/img/system/losa/35220000-SLAB-Outcorner_5.5kg.jpg', alt:'35220000 SLAB Outcorner_5.5kg'},
    {id:9, title:'35180000 END BEAM', img: '../../../assets/img/system/losa/35180000-END-BEAM.jpg', alt:'35180000 END BEAM'},
    {id:10, title:'35480000 Slab open panel_10.93kg', img: '../../../assets/img/system/losa/35480000-Slab-open-panel_10.93kg.jpg', alt:'35480000 Slab open panel_10.93kg'},
    {id:11, title:'35440000 Beam bottom slab panel_38.4kg', img: '../../../assets/img/system/losa/35440000-Beam-bottom-slab-panel_38.4kg.jpg', alt:'35440000-Beam bottom slab panel_38.4kg'},
    {id:12, title:'31470000-AL-AG Release_1.931kg', img: '../../../assets/img/system/losa/31470000-AL-AG-Release_1.931kg.jpg', alt:'31470000-AL-AG Release_1.931kg'},
    {id:13, title:'35200000 Joint Bar_0.67kg', img: '../../../assets/img/system/losa/35200000-Joint-Bar_0.67kg.jpg', alt:'35200000 Joint Bar_0.67kg'},
    {id:14, title:'35141000 AL-SUPPORT', img: '../../../assets/img/system/losa/35141000-AL-SUPPORT.jpg', alt:'35141000 AL-SUPPORT'},
    {id:15, title:'110411 V1 STEEL SUPPORT_10.9kg', img: '../../../assets/img/system/losa/110411-V1-STEEL-SUPPORT_10.9kg.jpg', alt:'110411 V1 STEEL SUPPORT_10.9kg'},
    {id:16, title:'110425 V2 STEEL SUPPORT_11.5kg', img: '../../../assets/img/system/losa/110425-V2-STEEL-SUPPORT_11.5kg.jpg', alt:'110425 V2 STEEL SUPPORT_11.5kg'},
    {id:17, title:'110414 V4 STEEL SUPPORT_13kg', img: '../../../assets/img/system/losa/110414-V4-STEEL-SUPPORT_13kg.jpg', alt:'110414 V4 STEEL SUPPORT_13kg'},
    {id:18, title:'110413 V3 STEEL SUPPORT_12.5kg', img: '../../../assets/img/system/losa/110413-V3-STEEL-SUPPORT_12.5kg.jpg', alt:'110413 V3 STEEL SUPPORT_12.5kg'},
    
]

const productoMuro = 
[
    {id:1, title:'AL WALL RK 2450x300-16.4kg', img: '../../../assets/img/system/muro/AL-WALL-RK-2450x300-16.4kg.jpg', alt:'AL-WALL-RK-2450x300-16.4kg'},
    {id:2, title:'AL WALL RK 2450x400-20.4kg', img: '../../../assets/img/system/muro/AL-WALL-RK-2450x400-20.4kg.jpg', alt:'AL-WALL-RK-2450x400-20.4kg'},
    {id:3, title:'AL WALL RK 2450x450-22.4kg', img: '../../../assets/img/system/muro/AL-WALL-RK-2450x450-22.4kg.jpg', alt:'AL-WALL-RK-2450x450-22.4kg'},
    {id:4, title:'AL WALL RK 2450x600-28.8kg', img: '../../../assets/img/system/muro/AL-WALL-RK-2450x600-28.8kg.jpg', alt:'AL-WALL-RK-2450x600-28.8kg'},

]

const productoEscalera = 
[
    {id:1, title:'36510000 Stair riser panel_6.24kg', img: '../../../assets/img/system/escalera/36510000-Stair-riser-panel_6.24kg.jpg', alt:'36510000-Stair-riser-panel_6.24kg'},
    {id:2, title:'36000000 Stair wall panelup_3.37kg', img: '../../../assets/img/system/escalera/36000000-Stair-wall-panelup_3.37kg.jpg', alt:'36000000-Stair-wall-panelup_3.37kg'},
    {id:3, title:'36510000 Stair step panel_5.05kg', img: '../../../assets/img/system/escalera/36510000-Stair-step-panel_5.05kg.jpg', alt:'36510000-Stair-step-panel_5.05kg'},
    {id:4, title:'36000000 Stair wall paneldw_3.37kg', img: '../../../assets/img/system/escalera/36000000-Stair-wall-paneldw_3.37kg.jpg', alt:'36000000-Stair-wall-paneldw_3.37kg'},
    {id:5, title:'36000000 Stair landing panel_11.43kg', img: '../../../assets/img/system/escalera/36000000-Stair-landing-panel_11.43kg.jpg', alt:'36000000-Stair-landing-panel_11.43kg'},
    {id:6, title:'36510000 Stair side panel_14.9kg', img: '../../../assets/img/system/escalera/36510000-Stair-side-panel_14.9kg.jpg', alt:'36510000-Stair-side-panel_14.9kg'},
    {id:7, title:'36510000-Gun-panel_17.34kg', img: '../../../assets/img/system/escalera/36510000-Gun-panel_17.34kg.jpg', alt:'36510000-Gun-panel_17.34kg'},
    {id:8, title:'36510000 Stair landing post panel1_0.55kg', img: '../../../assets/img/system/escalera/36510000-Stair-landing-post-panel1_0.55kg.jpg', alt:'36510000-Stair-landing-post-panel1_0.55kg'},
    {id:9, title:'36510000 Stair landing post panel2_1.47kg', img: '../../../assets/img/system/escalera/36510000-Stair-landing-post-panel2_1.47kg.jpg', alt:'36510000-Stair-landing-post-panel2_1.47kg'},
]

const productoSeguridad = 
[
    {id:1, title:'METAL GRADE WITH TOE BOARD 0CM_5.8kg', img: '../../../assets/img/system/seguridad/METAL-GRADE-WITH-TOE-BOARD-20CM_5.8kg.jpg', alt:'METAL-GRADE-WITH-TOE-BOARD-20CM_5.8kg'},
    {id:2, title:'CORNER MESH METAL FLOOR_36.30kg', img: '../../../assets/img/system/seguridad/CORNER-MESH-METAL-FLOOR_36.30kg.jpg', alt:'CORNER-MESH-METAL-FLOOR_36.30kg'},
    {id:3, title:'ELE.V External BracketStandard_10.3kg', img: '../../../assets/img/system/seguridad/ELE.V-External-BracketStandard_10.3kg.jpg', alt:'ELE.V-External-BracketStandard_10.3kg'},
    {id:4, title:'POST A TYPE_5kg', img: '../../../assets/img/system/seguridad/POST-A-TYPE_5kg.jpg', alt:'POST-A-TYPE_5kg'},
    {id:5, title:'POST B TYPE_4.5kg', img: '../../../assets/img/system/seguridad/POST-B-TYPE_4.5kg.jpg', alt:'POST-B-TYPE_4.5kg'},
    {id:6, title:'Tie Rod_1.52kg', img: '../../../assets/img/system/seguridad/Tie-Rod_1.52kg.jpg', alt:'Tie-Rod_1.52kg'},
    {id:7, title:'SLAB External Bracket_10.6kg', img: '../../../assets/img/system/seguridad/SLAB-External-Bracket_10.6kg.jpg', alt:'SLAB-External-Bracket_10.6kg'},
    {id:8, title:'WALL External BracketStandard_15.7kg', img: '../../../assets/img/system/seguridad/WALL-External-BracketStandard_15.7kg.jpg', alt:'WALL-External-BracketStandard_15.7kg'},
    {id:9, title:'LIFE LINE SUPPORT_17.8kg', img: '../../../assets/img/system/seguridad/LIFE-LINE-SUPPORT_17.8kg.jpg', alt:'LIFE-LINE-SUPPORT_17.8kg'},
    {id:10, title:'EXTERNAL WORKING PLATFORM_17.81', img: '../../../assets/img/system/seguridad/EXTERNAL-WORKING-PLATFORM_17.81.jpg', alt:'EXTERNAL-WORKING-PLATFORM_17.81'},
    {id:11, title:'EXTERNAL WORKING PLATFORM_19.40kg', img: '../../../assets/img/system/seguridad/EXTERNAL-WORKING-PLATFORM_19.40kg.jpg', alt:'EXTERNAL-WORKING-PLATFORM_19.40kg'},
    {id:12, title:'NON SKID FLOOR_5.8kg', img: '../../../assets/img/system/seguridad/NON-SKID-FLOOR_5.8kg.jpg', alt:'NON-SKID-FLOOR_5.8kg'},

]

const productoAccesorios = 
[
    {id:1, title:'38002800 AL WALLER BRACKET', img: '../../../assets/img/system/accesorio/38002800-AL-WALLER-BRACKET.jpg', alt:'38002800-AL-WALLER-BRACKET'},
    {id:2, title:'38001800 AL BOLT', img: '../../../assets/img/system/accesorio/38001800-AL-BOLT.jpg', alt:'38001800-AL-BOLT'},
    {id:3, title:'38000100 AL ROUND PIN', img: '../../../assets/img/system/accesorio/38000100-AL-ROUND-PIN.jpg', alt:'38000100-AL-ROUND-PIN'},
    {id:4, title:'38010600 AL PVC SLEEVE', img: '../../../assets/img/system/accesorio/38010600-AL-PVC-SLEEVE.jpg', alt:'38010600-AL-PVC-SLEEVE'},
    {id:5, title:'38000500 AL WEDGE PIN', img: '../../../assets/img/system/accesorio/38000500-AL-WEDGE-PIN.jpg', alt:'38000500-AL-WEDGE-PIN'},
    {id:6, title:'38000700 AL FLATE TIE EMBED_0.12kg', img: '../../../assets/img/system/accesorio/38000700-AL-FLATE-TIE-EMBED_0.12kg.jpg', alt:'38000700-AL-FLATE-TIE-EMBED_0.12kg'},
    {id:7, title:'38001110 AL FLAT TIE REUSED', img: '../../../assets/img/system/accesorio/38001110-AL-FLAT-TIE-REUSED.jpg', alt:'38001110-AL-FLAT-TIE-REUSED'},
    {id:8, title:'38000200 AL LONG PIN', img: '../../../assets/img/system/accesorio/38000200-AL-LONG-PIN.jpg', alt:'38000200-AL-LONG-PIN'},
]

const StyledSystemView = Styled.div`
    h2{
        padding: 2rem 0rem;
    }
    img{
        width: 80%;
        @media(min-width: 992px){
            width: 95%;
        }
    }
    .fondMain{
        background-color: var(--colorFondoPrincipal);
        width: auto;
        height: min-height;
        margin: 5rem 0rem;
        padding: 5rem 0rem;
    }
    .containerImg{
        display: flex;
        justify-content: center;
        @media(min-width: 992px){
            justify-content: flex-start;
        }

    }
    .containerProducts{
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: space-between;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .mobile{
        display: flex;
        flex-direction: column-reverse;
    }
    .desktop{
        display: flex;
        flex-direction: row;
    }
`