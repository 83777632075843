import Banner from "../../component/banner/Banner";
import LabelsGroups from "../../component/labels/LabelsGroups";
import Styled from "styled-components";

export default function BenefitView(){
    
    return(
        <StyledBenefitView>
            <Banner banner={banner}/>
            <div className="container">
                <h3>SENCILLO, MODULAR, RÁPIDO Y RENTABLE</h3>
                <LabelsGroups benefit={benefit}/>
            </div>
        </StyledBenefitView>
    )
}

const banner = 
[
    {id: 1, title: 'Beneficios', img: '../../../assets/img/project/house/bannerHome.jpg', alt: 'Imagen Banner' },
];

const benefit = 
[
    { id: 1, img: "../../../assets/img/benefit/calidad.png", alt: "Beneficio de alusystem", title: 'Calidad', info: 'Debido a la superficie lisa y paneles dimensionado con precisión no se necesita el uso de revoque después de fundir el hormigón.'},
    { id: 8, img: "../../../assets/img/benefit/velocidad.png", alt: "Beneficio de alusystem", title: 'Velocidad', info: 'Gracias a la facilidad de montaje, está garantizado a mantener el ciclo a 6 días comparado a otros sistemas de encofrados que ofrece entre 7 y 10 días.' },
    { id: 6, img: "../../../assets/img/benefit/seguridad.png", alt: "Beneficio de alusystem", title: 'Seguridad', info: 'No es necesario eliminar accesorios viga central durante el desmantelamiento de los paneles de losa.' },
    { id: 5, img: "../../../assets/img/benefit/montaje.png", alt: "Beneficio de alusystem", title: 'Montaje Fácil', info: 'No requiere un carpintero o un trabajador con mucha experiencia gracias a su diseño fácil para montar.' },
    { id: 7, img: "../../../assets/img/benefit/todoenuno.png", alt: "Beneficio de alusystem", title: 'Sistema "Todo en Uno"', info: 'Ofrecemos todo tipo de equipo necesario como msoportes para pared, losa, ascensor y plataformas externas con sistemas de encofrados de aluminio Kumkang Kind.' },
    { id: 4, img: "../../../assets/img/benefit/mobilidad.png", alt: "Beneficio de alusystem", title: 'Mobilidad', info: 'Sin necesidad de utilizar una grúa se puede trasladar el encofrado al siguiente piso con el material de losa.' },
    { id: 2, img: "../../../assets/img/benefit/diseno.png", alt: "Beneficio de alusystem", title: 'Libertad de Diseño', info: 'Diferente al sistema prefabricado, los sistemas de encofrados Alusystem ofrece un sistema “modular” que pueden ser utilizados en cualquier plano estructural o arquitectónico. 4 pisos por mes (6 días por piso).' },
    { id: 3, img: "../../../assets/img/benefit/durabilidad.png", alt: "Beneficio de alusystem", title: 'Durabilidad', info: 'El uso de aluminios de última generación(6061-T6) cede el monótono uso(hasta 250 veces) y resulta en el disminución de costos de construcción comparado a encofrados tradicionales.' },
];

const StyledBenefitView = Styled.div`
    h3{
        display: flex;
        justify-content: center;
        margin: 50px 0px;
        font-size: 1.8rem;
        text-align: center;
    }
`
