import Styled from "styled-components";

export default function Products(props){
    return(
        <StyleProducts>
            <div className="products">
            {props.product.map((data) => (
                <>
                    <img className="productImg" src={data.img} alt={data.alt}/>
                </>
            ))}
            </div>
        </StyleProducts>
    )
}

const StyleProducts = Styled.div`
    .products{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        @media(min-width: 992px){
            justify-content: flex-start;
        }
    }
    .productImg{
        width: 10rem;
        margin: 0.3rem;
    }
`