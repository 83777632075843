import Styled from "styled-components";
import Carousel from "../../component/carousel/Carousel";
import Technique from "../../component/technique/Technique";
import CardGrups from "../../component/card/CardGroup";
import Project from "../../component/project/Project";
import Video from "../../component/video/Video";

export default function HomeView(){
    return(
        <StyleHomeView>
            <Carousel/>
            <div className="container">
                <Technique/>
                <CardGrups listCard={listCard}/>
                <Video/>
                <div className="linea"></div>
                <Project/>
            </div>
        </StyleHomeView>
    )
}

const listCard = [
    { id: 1, title: "USOS Y COSTO", info: 'Alusystem = 280 USD M2 | Tradicional = 45 USD M2 | Flex = 150 USD M2', info2:'Alusystem 250 usos | Tradicional 5 usos | Flex 250 usos vigas y puntales, 10 usos fenólicos.'},
    { id: 2, title: "TIEMPO", info: 'Vivienda de 100 M2 | 8 operarios trabajando | 8 horas cada operario', info2: 'Alusystem 1 vivienda por día | Tradicional 1 vivienda cada 10 días | Flex 1 vivienda cada 4 días.'},
    { id: 3, title: "MANO DE OBRA", info: 'Sueldo mensual operario: 1400 USD | Sueldo por hora: 9 USD | 8 operarios por día: 576 USD', info2:'Alusystem 1 día de trabajo | Tradicional 10 dias de trabajo | Flex 4 dias de trabajo.'},
];

const StyleHomeView = Styled.div`

`