import Styled from "styled-components";

export default function Labels (props){
    return(
        <StyledLabels>
            <div className="contenBenefitInd">
                <img src={props.LabelsInfo.img} alt={props.LabelsInfo.alt} />
                <h4>{props.LabelsInfo.title}</h4>
                <p>{props.LabelsInfo.info}</p>
            </div>
        </StyledLabels>
    )
};

const StyledLabels = Styled.div`
    .contenBenefitInd{
        margin: 2rem;
        text-align: center;
    }
`