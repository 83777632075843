import './App.css';
import { Route, Routes } from 'react-router-dom';

import LayoutSimple from './layouts/variations/LayoutSimple';

//VISTAS DE LANDING
import HomeView from './views/landing/HomeView';
import SystemView from './views/landing/SystemView';
import ConstrucctionView from './views/landing/ConstructionView';
import BenefitView from './views/landing/BenefitView';
import ProcessView from './views/landing/ProcessView';
import UsView from './views/landing/UsView';

export default function App() {
  return (
    <Routes>  
      {/* RUTAS PARA FRONT CLIENTE */}
      <Route path="/" element={<LayoutSimple />}>
        <Route index element={<HomeView />} />
        <Route path="sistema" element={<SystemView/>} />
        <Route path="obras" element={<ConstrucctionView/>} />
        <Route path="beneficios" element={<BenefitView/>} />
        <Route path="proceso" element={<ProcessView/>} />
        <Route path="nosotros" element={<UsView/>} />
      </Route>
    </Routes>
  );
}