import { useState } from 'react';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';
import ProjectType from './ProjectType';

export default function Project (){

    const [showHouse, setShowHouse] = useState(true);
    const [showBuildingLow, setShowBuildingLow] = useState(false);
    const [showBuildingHigh, setShowBuildingHigh] = useState(false);
    const [showSkyscraper, setShowSkyscraper] = useState(false);

    const showHouseContent = () => {
        setShowHouse(true);
        setShowBuildingLow(false);
        setShowBuildingHigh(false);
        setShowSkyscraper(false);
    };
    const showBuildingLowContent = () => {
        setShowHouse(false);
        setShowBuildingLow(true);
        setShowBuildingHigh(false);
        setShowSkyscraper(false);
    };
    const showBuildingHighContent = () => {
        setShowHouse(false);
        setShowBuildingLow(false);
        setShowBuildingHigh(true);
        setShowSkyscraper(false);
    };
    const showSkyscraperContent = () => {
        setShowHouse(false);
        setShowBuildingLow(false);
        setShowBuildingHigh(false);
        setShowSkyscraper(true);
    };

    return(
        <StyledProject>
            <div className='containerProject'>
                <h2 className='titleProject'>NUESTROS PROYECTOS</h2>
                <p className='info'>Nuestra cartera diversa representa décadas de experiencia en construcción respaldada por una pasión por la calidad.</p>

                <div className='contenButton'>
                        <Link className={showHouse ? 'activeButton' : 'button'} onClick={showHouseContent}>Viviendas 1 y 2 Pisos</Link>
                        <Link className={showBuildingLow ? 'activeButton' : 'button'} onClick={showBuildingLowContent}>Edificios Bajos</Link>
                        <Link className={showBuildingHigh ? 'activeButton' : 'button'} onClick={showBuildingHighContent}>Edificios Altos</Link>
                        <Link className={showSkyscraper ? 'activeButton' : 'button'} onClick={showSkyscraperContent}>Rascacielos</Link>
                </div>
                <br />
                <div className={showHouse ? 'house' : 'house hidden'}>
                    <ProjectType projectType={house}/>
                </div>
                <div className={showBuildingLow ? 'buildingLow' : 'buildingLow hidden'}>
                    <ProjectType projectType={buildingLow}/>
                </div>
                <div className={showBuildingHigh ? 'buildingHigh' : 'buildingHigh hidden'}>
                    <ProjectType projectType={buildingHigh}/>
                </div>
                <div className={showSkyscraper ? 'skyscraper' : 'skyscraper hidden'}>
                    <ProjectType projectType={skyscraper}/>
                </div>
            </div>
        </StyledProject>
    )
}

const house = 
[
    { id:'house1', img: "../../../assets/img/project/house/house01.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
    { id:'house2', img: "../../../assets/img/project/house/house02.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
    { id:'house3', img: "../../../assets/img/project/house/house03.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
    { id:'house4', img: "../../../assets/img/project/house/house04.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
    { id:'house5', img: "../../../assets/img/project/house/house05.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
    { id:'house6', img: "../../../assets/img/project/house/house06.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
    { id:'house7', img: "../../../assets/img/project/house/house07.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
    { id:'house8', img: "../../../assets/img/project/house/house08.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
    { id:'house9', img: "../../../assets/img/project/house/house09.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
    { id:'house10', img: "../../../assets/img/project/house/house10.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
];

const buildingLow = 
[
    { id:'low1', img: "../../../assets/img/project/buildingLow/buildingLow01.jpg", alt: "Edificios bajos con alusystem"},
    { id:'low2', img: "../../../assets/img/project/buildingLow/buildingLow02.jpg", alt: "Edificios bajos con alusystem"},
    { id:'low3', img: "../../../assets/img/project/buildingLow/buildingLow03.jpg", alt: "Edificios bajos con alusystem"},
    { id:'low4', img: "../../../assets/img/project/buildingLow/buildingLow04.jpg", alt: "Edificios bajos con alusystem"},
];

const buildingHigh = 
[
    { id:'high1', img: "../../../assets/img/project/buildingHigh/buildingHigh01.jpg", alt: "Edificios altos con alusystem"},
    { id:'high2', img: "../../../assets/img/project/buildingHigh/buildingHigh02.jpg", alt: "Edificios altos con alusystem"},
    { id:'high3', img: "../../../assets/img/project/buildingHigh/buildingHigh03.jpg", alt: "Edificios altos con alusystem"},
    { id:'high4', img: "../../../assets/img/project/buildingHigh/buildingHigh04.jpg", alt: "Edificios altos con alusystem"},
    { id:'high5', img: "../../../assets/img/project/buildingHigh/buildingHigh05.jpg", alt: "Edificios altos con alusystem"},
];

const skyscraper = 
[
    { id:'skyscraper1', img: "../../../assets/img/project/skyscraper/skyscraper01.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper2', img: "../../../assets/img/project/skyscraper/skyscraper02.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper3', img: "../../../assets/img/project/skyscraper/skyscraper03.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper4', img: "../../../assets/img/project/skyscraper/skyscraper04.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper5', img: "../../../assets/img/project/skyscraper/skyscraper05.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper6', img: "../../../assets/img/project/skyscraper/skyscraper06.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper7', img: "../../../assets/img/project/skyscraper/skyscraper07.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper8', img: "../../../assets/img/project/skyscraper/skyscraper08.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper9', img: "../../../assets/img/project/skyscraper/skyscraper09.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper10', img: "../../../assets/img/project/skyscraper/skyscraper10.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper11', img: "../../../assets/img/project/skyscraper/skyscraper11.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper12', img: "../../../assets/img/project/skyscraper/skyscraper12.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper13', img: "../../../assets/img/project/skyscraper/skyscraper13.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper14', img: "../../../assets/img/project/skyscraper/skyscraper14.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper15', img: "../../../assets/img/project/skyscraper/skyscraper15.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper16', img: "../../../assets/img/project/skyscraper/skyscraper16.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper17', img: "../../../assets/img/project/skyscraper/skyscraper17.jpg", alt: "Rascacielos con alusystem"},
];

const StyledProject = Styled.div`
    .containerProject{
        margin: 4rem 0rem;
    }
    .titleProject{
        text-align: center;
    }
    .info{
        margin-top: 1.5rem;
        text-align: center;
    };
    .contenButton{
        display: flex;
        flex-direction: column;
        align-items: center;
        /* display: grid;
        grid-template-columns: repeat(2,1fr);
        align-items: center; */
        @media(min-width: 768px){
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 3rem;
        }
    }
    .button{
        font-size: 1.3rem;
        color: var(--colorGrisSecundario);
        padding: 0px 2rem;
        border-bottom: transparent solid 2px;
        @media(min-width: 992px){
            font-size: 1.8rem;
        }

    }
    .activeButton{
        font-size: 1.3rem;
        color: var(--colorPrincipal);
        padding: 0px 1.8rem;
        border-bottom: var(--colorPrincipal) solid 2px;
        @media(min-width: 992px){
            font-size: 1.8rem;
        }
    }
    .hidden {
        display: none;
    }
`