import { useState } from 'react';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';
import TechniqueHouse from './TechniqueHouse';
import TechniqueBuilding from './TechniqueBuilding';

export default function Technique(){
    const [showHouse, setShowHouse] = useState(true);
    const [showBuilding, setShowBuilding] = useState(false);

    const showHouseContent = () => {
        setShowHouse(true);
        setShowBuilding(false);
    };

    const showBuildingContent = () => {
        setShowHouse(false);
        setShowBuilding(true);
    };

    return(
        <StyleTechnique>
            <div className=''>
                <h2 className='titleTechnique'>MODULAR. RÁPIDO. RENTABLE.</h2>
                <p className='info'>
                    Ideal para la construcción masiva de viviendas tanto para proyectos unifamiliares como multifamiliares. Estos encofrados permiten la construcción de unidades en concreto sólido y sus beneficios son ilimitados. Bajo este sistema se reduce el tiempo de construcción, no requiere de equipo pesado para su movilización debido a lo liviano de sus componentes. <strong>Alusystem</strong> permite estructuras en concreto de alta calidad y durabilidad.
                </p>

                <div className='contenButton'>
                        <Link className={showHouse ? 'activeButton' : 'button'} onClick={showHouseContent}>Viviendas</Link>
                        <Link className={showBuilding ? 'activeButton' : 'button'} onClick={showBuildingContent}>Edificios</Link>
                </div>

                <div className={showHouse ? 'house' : 'house hidden'}>
                    <TechniqueHouse/>
                </div>
                <div className={showBuilding ? 'building' : 'building hidden'}>
                    <TechniqueBuilding/>
                </div>

            </div>
        </StyleTechnique>
    )
}

const StyleTechnique = Styled.div`
    .titleTechnique{
        margin-top: 3.5rem;
        text-align: center;
    };
    .info{ 
        margin-top: 1.5rem;
        text-align: center;
    };
    .contenButton{
        display: flex;
        justify-content: center;
        margin-top: 3rem;
    }
    .button{
        font-size: 1.8rem;
        color: var(--colorGrisSecundario);
        padding: 0px 2rem;
    }
    .activeButton{
        font-size: 1.8rem;
        color: var(--colorPrincipal);
        padding: 0px 1.8rem;
    }
    .hidden {
        display: none;
    }
`