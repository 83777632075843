import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Styled from 'styled-components';
import BurguerButton from "../../component/buttons/BurguerButton";

export default function Header (){

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleClick = () => {
        setClicked(!clicked);
        if (!clicked) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    };

    const handleMenuItemClick = () => {
        setClicked(false); // Cierra el menú burger cuando se hace clic en un botón del menú.
        document.body.style.overflow = "auto"; // Asegúrate de restaurar el desplazamiento del cuerpo.
    };

    return (
        <StyledHeader>
            <div className="container">
                {windowWidth >= 768 ? (
                    <div className="row">
                        <div className="col">
                            <Link to='/'><img src="../../../assets/img/logo.png" alt='Logo Alusystem'/></Link>
                        </div>
                        <div className="col" style={{display:'flex', alignItems:'center', justifyContent: 'flex-end'}} >
                            <ul className='contenMenu'>
                                <li className='itemMenu'><Link to='/sistema'>Sistema</Link></li>
                                <li className='itemMenu'><Link to='/beneficios'>Beneficios</Link></li>
                                <li className='itemMenu'><Link to='/proceso'>Procesos</Link></li>
                                <li className='itemMenu'><Link to='/obras'>Obras</Link></li>
                                <li className='itemMenu'><Link to='/nosotros'>Nosotros</Link></li>
                            </ul>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="containerBurger">
                        <Link to='/'><img src="../../../assets/img/logo.png" alt='Logo Alusystem'/></Link>
                            <BurguerButton clicked={clicked} handleClick={handleClick}/>
                        </div>
                        {clicked && (
                            <div className="burgerMenu">
                                <ul>
                                    <li><Link className='itemMenuBurger' to='/' onClick={handleMenuItemClick}><i className="fa-solid fa-house"></i> Inicio</Link></li>
                                    <li><Link className='itemMenuBurger' to='/sistema' onClick={handleMenuItemClick}><i class="fa-solid fa-trowel-bricks"></i> Sistema</Link></li>
                                    <li><Link className='itemMenuBurger' to='/beneficios' onClick={handleMenuItemClick}><i class="fa-solid fa-money-bill-trend-up"></i>Beneficios</Link></li>
                                    <li><Link className='itemMenuBurger' to='/proceso' onClick={handleMenuItemClick}><i class="fa-solid fa-screwdriver-wrench"></i> Procesos</Link></li>
                                    <li><Link className='itemMenuBurger' to='/obras' onClick={handleMenuItemClick}><i class="fa-solid fa-building"></i> Obras</Link></li>
                                    <li><Link className='itemMenuBurger' to='/nosotros' onClick={handleMenuItemClick}><i class="fa-solid fa-user-group"></i> Nosotros</Link></li>
                                </ul>
                            </div>
                        )}
                    </>
                )}
            </div>
        </StyledHeader>
    );
}

const StyledHeader = Styled.div`
    img{
        width: 160px; //130 antes de martin
    }
    .contenMenu{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0px !important;
    }
    .itemMenu{
        font-weight: 600; //antes del cambio de martin
        font-size: 1.2rem; //antes del cambio de martin
        padding: 0rem 1rem;
        border-bottom: transparent solid 2px;
    }
    .itemMenu:hover{
        padding: 0rem 1rem;
        border-bottom: var(--colorPrincipal) solid 2px;
    }
    .containerBurger{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    li{
        padding: 1rem 0rem;
    }
    .itemMenuBurger{
        padding: 0.5rem 0rem;
        border-bottom: transparent solid 2px;
    }
    .itemMenuBurger:hover{
        padding: 0.5rem 0rem;
        border-bottom: var(--colorPrincipal) solid 2px;
    }
    i{
        padding: 2px;
    }
`