import Styled from "styled-components";

export default function Carousel(){
    return(
        <StyledCarousel>
            <div className="container">
                <div className="slogan">
                    <h2>SISTEMA TODO EN UNO</h2>
                    <p>Sistema monolítico (pared losa) 100% hormigón</p>
                </div>

            </div>
            <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="../../../assets/img/slider01.jpg" className="d-block w-100" alt="imagen pincipal home"/>
                    </div>
                    <div className="carousel-item">
                        <img src="../../../assets/img/slider02.jpg" className="d-block w-100" alt="imagen pincipal home"/>
                    </div>
                    <div className="carousel-item">
                        <img src="../../../assets/img/slider03.jpg" className="d-block w-100" alt="imagen pincipal home"/>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </StyledCarousel>
    )
}

const StyledCarousel = Styled.div`
    .slogan{
        display: none;
        @media (min-width: 768px){
            display: block;
            position: absolute;
            z-index: 2;
            color: #fff;
            margin-top: 13%;
            width: 25rem;
            /* background-color: rgba(211, 211, 211, 0.2);
            padding: 5rem; */
        }
        @media(min-width: 992px){
            margin-top: 18%;
        }
    }
    h2, p{
        display: none;
        @media (min-width: 768px){
            display: block;
            color: #fff;
        }
    }
`