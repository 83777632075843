import Styled from "styled-components";
import Labels from "./Labels";

export default function LabelsGroups (props){
    return(
        <StyledBenefitGroup>
            <div className="containerBenefit">
                {props.benefit.map((data) => (
                    <Labels key={data.id} LabelsInfo={data}/>
                ))}        
            </div>
        </StyledBenefitGroup>
    )    
}

const StyledBenefitGroup = Styled.div`
    .containerBenefit{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        
        @media (min-width: 768px){
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: 992px){
            grid-template-columns: repeat(4, 1fr);
        }
    }
`