import styled from "styled-components";
import Banner from "../../component/banner/Banner";
import Maps from "../../component/maps/Maps";

export default function UsView(){
    return(
        <StyledUsView>
            <Banner banner={banner}/>
            <div className="container">

                <div className="row" style={{marginTop: '5rem'}}>
                    <div className="col-xl-4">
                        <p><strong>Alusystem</strong> surge como una división dentro de <strong>Sinis</strong>. Somos los primeros en traer este innovador y revolucionario sistema de encofrados a Argentina. Nuestro objetivo es que puedas acelerar tus proyectos a un precio mas bajo en comparación con otros sistemas. <strong>Alusystem</strong> ofrece la mejor confianza, seguridad y eficacia. Somos la empresa argentina con el sistema más innovador disponible en el mercado.</p>
                    </div>
                    <div className="col-xl-8" style={{ display: 'flex', alignItems: 'center'}}>
                        <div className="containerImg">
                            <img src="../../../assets/img/us/nosotros.jpg" alt="" />
                        </div>
                    </div>
                </div>

                <div className="linea"></div>
                
                <div className="row">
                    <div className="col-xl-4">
                        <h3>SINIS</h3>
                        <p>Comenzamos en 2006 a su vez como una división de encofrados dentro de Leiten. Desde entonces y acompañando la evolución del mercado, nos fuimos configurando como una empresa independiente. En el año 2000 desarrollamos el departamento de ingeniería para brindar un asesoramiento completo a nuestros clientes. El asesoramiento que realizamos incluye diseño, cálculo y cómputo de los sistemas de encofrado. Hoy somos la empresa argentina líder en fabricación, venta y alquiler de encofrados para columnas, losas, tabiques y muros; y contamos con capacidades fabriles para la producción de todas las piezas metálicas de los encofrados.
                        </p>
                    </div>
                    <div className="col-xl-8" style={{ display: 'flex', alignItems: 'center'}}>
                        <div className="containerImg">
                            <img src="../../../assets/img/us/nosotros2.jpg" alt="" />
                        </div>
                    </div>
                </div>

                <div className="linea"></div>
                
                <div className="row">
                    <div className="col-xl-4">
                        <h3>INVENTARIO</h3>
                        <p>Tenemos disponibilidad permanente en todos los modelos de texturas y en todo tipo de vigas, tabiques y accesorios que componen cada uno de nuestros sistemas de encofrados en <strong>Sinis</strong>.
                        </p>
                        <h3>FÁBRICA</h3>
                        <p>En nuestro depósito de 50.000 mts cuadrados disponemos de todos los sistemas de encofrado para compra o alquiler inmediatos.
                        Contamos también con las capacidades y la infraestructura necesaria para la fabricación de puntales rectos, puntales inclinados, tabiques, torres de carga, sistema de barras Dywidag, bases regulables y todos los accesorios.
                        Estamos equipados con: Cortadoras y Dobladoras de chapa CNC, Equipos de corte CNC de 5 ejes para corte y perforación de tubos y perfiles y Laminadoras de rosca de 2 y 3 rodillos para barras y tubos.
                        </p>
                    </div>
                    <div className="col-xl-8" style={{ display: 'flex', alignItems: 'center'}}>
                        <div className="containerImg">
                            <img src="../../../assets/img/us/nosotros3.jpg" alt="" />
                        </div>
                    </div>
                </div>
                <div className="linea"></div>
                <h3>SUCURSALES</h3>
                <p>Nuestra casa central está ubicada en Caseros, provincia de Buenos Aires. Contamos con 8 sucursales y una amplia red una red de distribución a nivel nacional. En cada uno de nuestros puntos de atención, nuestros clientes pueden ver los sistemas de encofrados que están en venta y alquiler.
                </p>
                <Maps/>
            </div>
        </StyledUsView>
    )
}

const banner = 
[
    {id: 1, title: 'Nosotros', img: '../../../assets/img/project/buildingHigh/bannerHigh.jpg', alt: 'Imagen Banner' },
];

const StyledUsView = styled.div`
    h3{
        color: var(--colorPrincipal);
        margin-top: 3rem;
    }
    .containerImg{
        width: 800px;
        margin: 0rem 2rem 2rem 2rem;
    }
    .containerImg img{
        width: 100%;
    }
    .contenMap{
        width: 400px;
        height: 400px;
    }
    iframe{
        padding: 1rem 0rem;
    }
`