import Styled from "styled-components";

export default function Video(){
    return(
        <StyleVideo>
            <div className="row" style={{margin: '5rem 0rem'}}>
                <div className="col-lg-6">
                    <div className="containerInfo">
                        <h2>PROCESO DE INSTALACIÓN</h2>
                        <p>En <strong>Alusystem</strong> somos representantes oficiales de la marca KumKangKind, líderes en encofrados de aluminios.
                        El sistema es liviano, 24kg/m2, por lo que todo el proceso de montaje se realiza sin asistencia de equipos pesados. El sencillo sistema de pins y cuñas hace el montaje más fácil. Los trabajadores sólo necesitan herramientas de mano para montar todo el sistema con facilidad.
                        Te invitamos a ver el video del proceso de instalación.</p>
                        <div className="img-logo">
                            <img src="../../../assets/img/kumkang-kind_logo_200px.jpg" alt='Logo Alusystem'/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="containerVideo">
                        <iframe className="video"
                            title="Video Institucional"
                            src={"https://player.vimeo.com/video/859891342?controls=0?&autopause=false&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1"}
                            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen>
                        </iframe>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
            </div>
        </StyleVideo>
    )
}

const StyleVideo = Styled.div `
    h3{
        color: var(--colorPrincipal);
        font-size: 3rem;
        @media (min-width: 992px){
            font-size: 2rem;
        }
    }
    p{
        margin-top: 2rem;
    }
    .img-logo{
        display: flex;
    justify-content: center;
    }
    .containerInfo{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .containerVideo{
        margin-top: 0rem;
        height: 30vh;
        width: auto;
        @media(min-width: 992px){
            height: 40vh;
        }
        iframe {
            height: 100%;
            width: 100%;
        }
    }
`