import Styled from "styled-components";
import { Link } from "react-router-dom";
import { useState } from 'react';
import Banner from "../../component/banner/Banner";
import Passed from "../../component/passed/Passed";
import Video from "../../component/video/Video";

export default function ProcessView(){
    const [showPassed1, setShowPassed1] = useState(true);
    const [showPassed2, setShowPassed2] = useState(false);
    const [showPassed3, setShowPassed3] = useState(false);
    const [showPassed4, setShowPassed4] = useState(false);
    const [showPassed5, setShowPassed5] = useState(false);
    const [showPassed6, setShowPassed6] = useState(false);
    const [showPassed7, setShowPassed7] = useState(false);

    const showPassed1Content = () => {
        setShowPassed1(true);
        setShowPassed2(false);
        setShowPassed3(false);
        setShowPassed4(false);
        setShowPassed5(false);
        setShowPassed6(false);
        setShowPassed7(false);
    };
    const showPassed2Content = () => {
        setShowPassed1(false);
        setShowPassed2(true);
        setShowPassed3(false);
        setShowPassed4(false);
        setShowPassed5(false);
        setShowPassed6(false);
        setShowPassed7(false);
    };
    const showPassed3Content = () => {
        setShowPassed1(false);
        setShowPassed2(false);
        setShowPassed3(true);
        setShowPassed4(false);
        setShowPassed5(false);
        setShowPassed6(false);
        setShowPassed7(false);
    };
    const showPassed4Content = () => {
        setShowPassed1(false);
        setShowPassed2(false);
        setShowPassed3(false);
        setShowPassed4(true);
        setShowPassed5(false);
        setShowPassed6(false);
        setShowPassed7(false);
    };
    const showPassed5Content = () => {
        setShowPassed1(false);
        setShowPassed2(false);
        setShowPassed3(false);
        setShowPassed4(false);
        setShowPassed5(true);
        setShowPassed6(false);
        setShowPassed7(false);
    };
    const showPassed6Content = () => {
        setShowPassed1(false);
        setShowPassed2(false);
        setShowPassed3(false);
        setShowPassed4(false);
        setShowPassed5(false);
        setShowPassed6(true);
        setShowPassed7(false);
    };
    const showPassed7Content = () => {
        setShowPassed1(false);
        setShowPassed2(false);
        setShowPassed3(false);
        setShowPassed4(false);
        setShowPassed5(false);
        setShowPassed6(false);
        setShowPassed7(true);
    };

    return(
        <StyledProcessView>
            <Banner banner={banner}/>
            <div className="container" style={{position: 'relative'}}>
            <Video/>
            <div className="linea"></div>
                <ul className="contenSubMenu">
                    <Link className={showPassed1 ? 'activeButton' : 'button'} onClick={showPassed1Content}>Paso 1</Link>
                    <Link className={showPassed2 ? 'activeButton' : 'button'} onClick={showPassed2Content}>Paso 2</Link>
                    <Link className={showPassed3 ? 'activeButton' : 'button'} onClick={showPassed3Content}>Paso 3</Link>
                    <Link className={showPassed4 ? 'activeButton' : 'button'} onClick={showPassed4Content}>Paso 4</Link>
                    <Link className={showPassed5 ? 'activeButton' : 'button'} onClick={showPassed5Content}>Paso 5</Link>
                    <Link className={showPassed6 ? 'activeButton' : 'button'} onClick={showPassed6Content}>Paso 6</Link>
                    <Link className={showPassed7 ? 'activeButton' : 'button'} onClick={showPassed7Content}>Paso 7</Link>
                </ul>
                <div className={showPassed1 ? 'passed' : 'passed hidden'}>
                    <Passed passed={passed1}/>
                </div>
                <div className={showPassed2 ? 'passed' : 'passed hidden'}>
                    <Passed passed={passed2}/>
                </div>
                <div className={showPassed3 ? 'passed' : 'passed hidden'}>
                    <Passed passed={passed3}/>
                </div>
                <div className={showPassed4 ? 'passed' : 'passed hidden'}>
                    <Passed passed={passed4}/>
                </div>
                <div className={showPassed5 ? 'passed' : 'passed hidden'}>
                    <Passed passed={passed5}/>
                </div>
                <div className={showPassed6 ? 'passed' : 'passed hidden'}>
                    <Passed passed={passed6}/>
                </div>
                <div className={showPassed7 ? 'passed' : 'passed hidden'}>
                    <Passed passed={passed7}/>
                </div>
            </div>
        </StyledProcessView>
    )
}

const banner = 
[
    {id: 1, title: 'Proceso', img: '../../../assets/img/project/buildingLow/bannerLow.jpg', alt: 'Imagen Banner' },
];

const passed1 =
[
    {id: 'passed1', img1: '../../../assets/img/passed/passed1A.jpg', alt1: 'Paso 1 imagen A', img2: '../../../assets/img/passed/passed1B.jpg', alt2: 'Paso 1 imagen B', title: 'Estructura de línea', info: 'Arriba de todo un texto que haga como la introducción a la sección. Fotos del paso a paso acompañado de videos de youtube de cómo se arma.' },
];
const passed2 =
[
    {id: 'passed2', img1: '../../../assets/img/passed/passed2A.jpg', alt1: 'Paso 2 imagen A', img2: '../../../assets/img/passed/passed2B.jpg', alt2: 'Paso 2 imagen B', title: 'Colocar el panel de pared(I/C + WALL)', info: 'Arriba de todo un texto que haga como la introducción a la sección. Fotos del paso a paso acompañado de videos de youtube de cómo se arma.' },
];
const passed3 =
[
    {id: 'passed3', img1: '../../../assets/img/passed/passed3A.jpg', alt1: 'Paso 3 imagen A', img2: '../../../assets/img/passed/passed3B.jpg', alt2: 'Paso 3 imagen B', title: 'Instalar el panel de viga(Beam + SC)', info: 'Arriba de todo un texto que haga como la introducción a la sección. Fotos del paso a paso acompañado de videos de youtube de cómo se arma.' },
];
const passed4 =
[
    {id: 'passed4', img1: '../../../assets/img/passed/passed4A.jpg', alt1: 'Paso 4 imagen A', img2: '../../../assets/img/passed/passed4B.jpg', alt2: 'Paso 4 imagen B', title: 'Instalar el hoyo de escalera y elevador', info: 'Arriba de todo un texto que haga como la introducción a la sección. Fotos del paso a paso acompañado de videos de youtube de cómo se arma.' },
];
const passed5 =
[
    {id: 'passed5', img1: '../../../assets/img/passed/passed5A.jpg', alt1: 'Paso 5 imagen A', img2: '../../../assets/img/passed/passed5B.jpg', alt2: 'Paso 5 imagen B', title: 'Instalar el panel de losa', info: 'Arriba de todo un texto que haga como la introducción a la sección. Fotos del paso a paso acompañado de videos de youtube de cómo se arma.' },
];
const passed6 =
[
    {id: 'passed6', img1: '../../../assets/img/passed/passed6A.jpg', alt1: 'Paso 6 imagen A', img2: '../../../assets/img/passed/passed6B.jpg', alt2: 'Paso 6 imagen B', title: 'Terminación de instalar el panel de losa', info: 'Arriba de todo un texto que haga como la introducción a la sección. Fotos del paso a paso acompañado de videos de youtube de cómo se arma.' },
];
const passed7 =
[
    {id: 'passed7', img1: '../../../assets/img/passed/passed7A.jpg', alt1: 'Paso 7 imagen A', img2: '../../../assets/img/passed/passed7B.jpg', alt2: 'Paso 7 imagen B', title: 'Instalación de los componentes de plomería, eléctricos y barras de acero de refuerzo', info: 'Arriba de todo un texto que haga como la introducción a la sección. Fotos del paso a paso acompañado de videos de youtube de cómo se arma.' },
];

const StyledProcessView = Styled.div`
    .contenSubMenu{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding-left: 0rem;
        @media(min-width: 768px){
            display: flex;
            justify-content: center;
            margin-top: 5rem;
        }
        a{
            font-size:1.2rem;
            @media(min-width: 768px){
                font-size: 1.5rem;
            }
        }
    }
    .contenButton{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        align-items: center;
        @media(min-width: 768px){
            display: flex;
            justify-content: center;
            margin-top: 3rem;
        }
    }
    .button{
        font-size: 1.8rem;
        color: var(--colorGrisSecundario);
        padding: 0px 1.2rem;
        text-align: center;
        border-bottom: transparent solid 2px;
        @media(min-width: 768px){
            padding: 0px 2rem;
        }

    }
    .activeButton{
        font-size: 1.8rem;
        color: var(--colorPrincipal);
        padding: 0px 1.2rem;
        text-align: center;
        border-bottom: var(--colorPrincipal) solid 2px;
        @media(min-width: 768px){
            padding: 0px 2rem;
        }
    }
    .hidden {
        display: none;
    }
`