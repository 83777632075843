import Styled from "styled-components";

export default function Passed(props){
    return(
        <StyledPassed>
            <div className="col-12 contanerPassed">
                <div className="row">
                {props.passed.map((data) => (
                    <>
                        <div className="col-md-12 col-lg-5 containerInfo">
                            <h2>{data.title}</h2>
                            <p>{data.info}</p>
                        </div>
                        <div className="col-md-12 col-lg-7 containerImg">
                            <img src={data.img1} alt={data.alt1}></img>
                            <img src={data.img2} alt={data.alt2}></img>    
                        </div>
                    </>
                ))}
            </div>
        </div>
        </StyledPassed>
    )
}

const StyledPassed = Styled.div`
    .contanerPassed{
        margin-bottom: 2rem;
    }
    .containerInfo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1.5rem;
        h2{
            font-size: 2rem;
        }
    }
    .containerImg{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        img{
            padding: 1rem;
            @media(min-width: 992px){
                width: 50%;
            }
        }
        @media (min-width: 992px){
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }
`