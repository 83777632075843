import Styled from "styled-components";
import React, { useState } from 'react';
import ProjectType from "../../component/project/ProjectType";

export default function ConstrucctionView() {

    const [visibleGallery, setVisibleGallery] = useState(null);

    const handleContainerClick = (gallery) => {
        if (visibleGallery === gallery) {
        setVisibleGallery(null);
        } else {
        setVisibleGallery(gallery);
        }
    };

    const getGalleryVisibility = (gallery) => {
        return gallery === visibleGallery ? '' : 'hidden';
    };

    return (
        <StylesConstrucctionView>
            <div className="containerBannerHome" onClick={() => handleContainerClick('galleryHome')}>Viviendas 1 y 2 Pisos</div>
            <div className={`galleryHome ${getGalleryVisibility('galleryHome')}`}>
                <div className="container">
                    <ProjectType projectType={house}/>
                </div>
            </div>
            
            <div className="containerBannerBuildingLow" onClick={() => handleContainerClick('galleryBuildingLow')}>Edificios Bajos</div>
            <div className={`galleryBuildingLow ${getGalleryVisibility('galleryBuildingLow')}`}>
                <div className="container">
                    <ProjectType projectType={buildingLow}/>
                </div>    
            </div>
            
            <div className="containerBannerBuildingHigh" onClick={() => handleContainerClick('galleryBuildingHigh')}>Edificios Altos</div>
            <div className={`galleryBuildingHigh ${getGalleryVisibility('galleryBuildingHigh')}`}>
                <div className="container">
                    <ProjectType projectType={buildingHigh}/>
                </div>
            </div>
            
            <div className="containerBannerSkyscraper" onClick={() => handleContainerClick('gallerySkyscraper')}>Rascacielos</div>
            <div className={`gallerySkyscraper ${getGalleryVisibility('gallerySkyscraper')}`}>
                <div className="container">
                    <ProjectType projectType={skyscraper}/>
                </div>
            </div>
        </StylesConstrucctionView>
    );
}


const StylesConstrucctionView = Styled.div`
    .containerBannerHome{
        height: 10rem;
        background-image: url('../../../../assets/img/project/house/bannerHome.jpg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position-y: -80px;
        margin-bottom: 2rem;
        cursor:pointer;
        //css para texto
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        //color: var(--colorPrincipal);
        font-size: 2rem;
        @media (min-width: 576px){
            background-position-y: -150px;
        }
        @media (min-width: 768px){
            background-position-y: -200px;
        }
        @media (min-width: 992px){
            background-position-y: -250px;
        }
        @media (min-width: 1200px){
            background-position-y: -300px;
        }
        @media (min-width: 1400px){
            background-position-y: -480px;
        }
    }

    .containerBannerBuildingLow{
        height: 10rem;
        background-image: url('../../../../assets/img/project/buildingLow/bannerLow.jpg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position-y: -40px;
        margin-bottom: 2rem;
        cursor:pointer;
        //css para texto
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        //color: var(--colorPrincipal);
        font-size: 2rem; @media (min-width: 576px){
            background-position-y: -150px;
        }
        @media (min-width: 768px){
            background-position-y: -200px;
        }
        @media (min-width: 992px){
            background-position-y: -250px;
        }
        @media (min-width: 1200px){
            background-position-y: -300px;
        }
        @media (min-width: 1400px){
            background-position-y: -480px;
        }
    }
    .containerBannerBuildingHigh{
        height: 10rem;
        background-image: url('../../../../assets/img/project/buildingHigh/bannerHigh.jpg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position-y: -60px;
        margin-bottom: 2rem;
        cursor:pointer;
        //css para texto
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        //color: var(--colorPrincipal);
        font-size: 2rem;
        @media (min-width: 576px){
            background-position-y: -150px;
        }
        @media (min-width: 768px){
            background-position-y: -200px;
        }
        @media (min-width: 992px){
            background-position-y: -250px;
        }
        @media (min-width: 1200px){
            background-position-y: -300px;
        }
        @media (min-width: 1400px){
            background-position-y: -500px;
        }
    }
    .containerBannerSkyscraper{
        height: 10rem;
        background-image: url('../../../../assets/img/project/skyscraper/bannerSkyscraper.jpg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position-y: -50px;
        margin-bottom: 2rem;
        cursor:pointer;
        //css para texto
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        //color: var(--colorPrincipal);
        font-size: 2rem;
        @media (min-width: 576px){
            background-position-y: -150px;
        }
        @media (min-width: 768px){
            background-position-y: -200px;
        }
        @media (min-width: 992px){
            background-position-y: -250px;
        }
        @media (min-width: 1200px){
            background-position-y: -300px;
        }
        @media (min-width: 1400px){
            background-position-y: -500px;
        }
    }
    .hidden {
        display: none;
}
`
const house = 
[
    { id:'house1', img: "../../../assets/img/project/house/house01.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
    { id:'house2', img: "../../../assets/img/project/house/house02.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
    { id:'house3', img: "../../../assets/img/project/house/house03.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
    { id:'house4', img: "../../../assets/img/project/house/house04.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
    { id:'house5', img: "../../../assets/img/project/house/house05.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
    { id:'house6', img: "../../../assets/img/project/house/house06.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
    { id:'house7', img: "../../../assets/img/project/house/house07.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
    { id:'house8', img: "../../../assets/img/project/house/house08.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
    { id:'house9', img: "../../../assets/img/project/house/house09.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
    { id:'house10', img: "../../../assets/img/project/house/house10.jpg", alt: "Casas de 1 y 2 pisos con alusystem"},
];

const buildingLow = 
[
    { id:'low1', img: "../../../assets/img/project/buildingLow/buildingLow01.jpg", alt: "Edificios bajos con alusystem"},
    { id:'low2', img: "../../../assets/img/project/buildingLow/buildingLow02.jpg", alt: "Edificios bajos con alusystem"},
    { id:'low3', img: "../../../assets/img/project/buildingLow/buildingLow03.jpg", alt: "Edificios bajos con alusystem"},
    { id:'low4', img: "../../../assets/img/project/buildingLow/buildingLow04.jpg", alt: "Edificios bajos con alusystem"},
];

const buildingHigh = 
[
    { id:'high1', img: "../../../assets/img/project/buildingHigh/buildingHigh01.jpg", alt: "Edificios altos con alusystem"},
    { id:'high2', img: "../../../assets/img/project/buildingHigh/buildingHigh02.jpg", alt: "Edificios altos con alusystem"},
    { id:'high3', img: "../../../assets/img/project/buildingHigh/buildingHigh03.jpg", alt: "Edificios altos con alusystem"},
    { id:'high4', img: "../../../assets/img/project/buildingHigh/buildingHigh04.jpg", alt: "Edificios altos con alusystem"},
    { id:'high5', img: "../../../assets/img/project/buildingHigh/buildingHigh05.jpg", alt: "Edificios altos con alusystem"},
];

const skyscraper = 
[
    { id:'skyscraper1', img: "../../../assets/img/project/skyscraper/skyscraper01.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper2', img: "../../../assets/img/project/skyscraper/skyscraper02.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper3', img: "../../../assets/img/project/skyscraper/skyscraper03.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper4', img: "../../../assets/img/project/skyscraper/skyscraper04.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper5', img: "../../../assets/img/project/skyscraper/skyscraper05.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper6', img: "../../../assets/img/project/skyscraper/skyscraper06.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper7', img: "../../../assets/img/project/skyscraper/skyscraper07.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper8', img: "../../../assets/img/project/skyscraper/skyscraper08.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper9', img: "../../../assets/img/project/skyscraper/skyscraper09.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper10', img: "../../../assets/img/project/skyscraper/skyscraper10.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper11', img: "../../../assets/img/project/skyscraper/skyscraper11.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper12', img: "../../../assets/img/project/skyscraper/skyscraper12.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper13', img: "../../../assets/img/project/skyscraper/skyscraper13.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper14', img: "../../../assets/img/project/skyscraper/skyscraper14.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper15', img: "../../../assets/img/project/skyscraper/skyscraper15.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper16', img: "../../../assets/img/project/skyscraper/skyscraper16.jpg", alt: "Rascacielos con alusystem"},
    { id:'skyscraper17', img: "../../../assets/img/project/skyscraper/skyscraper17.jpg", alt: "Rascacielos con alusystem"},
];
