import Styled  from "styled-components";

export default function Card(props){
    return(
        <StyledCard>
            <div className="contenCard">

                <div className="card">
                    <h3>{props.CardInfo.title}</h3>
                    <div className="card-body">
                        <p className="card-text">{props.CardInfo.info}</p>
                        <p className="card-text">{props.CardInfo.info2}</p>
                        <div className="contenGraphic">
                            <div className="">
                                
                                <p className="">Alusystem</p>
                                <div class="graphico">
                                    <div class="alusystem"></div>
                                </div>
                                
                                <p className="">Flex</p>
                                <div class="graphico">
                                    <div class="flex"></div>
                                </div>

                                <p className="">Tradicional</p>
                                <div class="graphico">
                                    <div class="tradicional"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </StyledCard>
    )
}

const StyledCard = Styled.div`
    .contenCard{
        display: flex;
        justify-content: center;
        margin: 1.5rem 0px;
        padding: 0.5rem;
        @media (min-width: 768px){
            margin: 1rem 0px;
        }
        @media (min-width: 992px){
            margin: 3.5rem 0px;
        }
    }
    .card{
        padding:2rem;
    }
    .graphico{
        display: block;
        width: 100%;
        height: var(--alturaBarra);
        background: #fff;
        box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
        margin-bottom: 14px;
    }
    .alusystem{
        display: block;
        height: var(--alturaBarra);
        background: #f05a1a;
        width: 15%;
    }
    .flex{
        display: block;
        height: var(--alturaBarra);
        background: var(--colorGrisSecundario);
        width: 40%;
    }
    .tradicional{
        display: block;
        height: var(--alturaBarra);
        background: var(--colorGRisTerciario);
        width: 100%;
    }
`