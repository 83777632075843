import styled from "styled-components";

export default function Maps(){
    return(
        <StyledMaps>

        <div className="container">
            <div className="row">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-4 contenInfo">
                                <h4>Buenos Aires</h4>
                                <p>3 de Febrero 4456, Caseros, Buenos Aires - Caseros - Buenos Aires</p>
                                <p>(011) 5263 8899</p>
                                <p>(011) 5263 8899</p>
                                <p>Lunes a Viernes de 8 a 17</p>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-8 containerMap">
                                <iframe className="contenMap" title="Sucursal Buenos Aires" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.1770262565815!2d-58.56756012486912!3d-34.624966458619596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb813d3bcc08d%3A0x454b903b8190aa8d!2sLeiten%20SA!5e0!3m2!1ses!2sar!4v1695901894405!5m2!1ses!2sar" width="auto" height="auto" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-4 contenInfo">
                                <h4>Rosario</h4>
                                <p>Ing Thedy 311 Bis, Rosario - Rosario - Santa Fe</p>
                                <p>(0341) 437 4153</p>
                                <p>(0341) 430 7235</p>
                                <p>Lunes a Viernes de 8 a 12 y de 13 a 17 | Sábados de 8 a 12</p>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-8 containerMap" >
                                <iframe className="contenMap" title="Sucursal Rosario" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3349.0072167296917!2d-60.66815862496239!3d-32.924407570764544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b654add8376e13%3A0x951e52f6bf63d6b0!2sLeiten%20ARG%20S.A.!5e0!3m2!1ses!2sar!4v1695899695827!5m2!1ses!2sar" width="auto" height="auto" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>

                    <div className="linea"></div>
                    
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-4 contenInfo">
                                <h4>Tucumán</h4>
                                <p>Avenida Adolfo de la Vega 470, San Miguel de Tucumán - San Miguel de Tucumán - Tucumán</p>
                                <p>(0381) 587 5337</p>
                                <p>(0381) 423 7038</p>
                                <p>Lunes a Viernes de 8 a 12 y de 13 a 17 | Sábados de 8 a 12</p>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-8 containerMap">
                                <iframe className="contenMap" title="Sucursal Tucumán" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3560.3939201709763!2d-65.25501072526507!3d-26.82742048964407!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94225ceda8c452eb%3A0xc183fa042d75972e!2sLeiten%20NOA!5e0!3m2!1ses!2sar!4v1695902071315!5m2!1ses!2sar" width="auto" height="auto" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-4 contenInfo">
                                <h4>Santa Fe</h4>
                                <p>Av. Galicia 2360, Santa Fe - Santa Fe Capital - Santa Fe</p>
                                <p>(0342) 576 3225</p>
                                <p>(0342) 469 1294</p>
                                <p>Lunes a Viernes de 8 a 17 | Sábados de 8 a 12</p>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-8 containerMap">
                                <iframe className="contenMap" title="Sucursal Santa Fe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3398.033236145353!2d-60.69626562518418!3d-31.605549048327138!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b5074445f4f779%3A0x4ce4a567379a4aac!2sLeiten%20Santa%20Fe!5e0!3m2!1ses!2sar!4v1695902029250!5m2!1ses!2sar" width="auto" height="auto" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>

                    <div className="linea"></div>
                    
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-4 contenInfo">
                                <h4>Córdoba</h4>
                                <p>Av. Circunvalación Agustín Tosco 3974 - Córdoba Capital - Córdoba</p>
                                <p>(0351) 563 6491</p>
                                <p>(0351) auto 3000</p>
                                <p>Lunes a Viernes de 8 a 17 hs</p>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-8 containerMap">
                                <iframe className="contenMap" title="Sucursal Córdoba" src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d6939.495677141061!2d-64.25817227775278!3d-31.4531806554866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sleiten%20cordoba!5e0!3m2!1ses!2sar!4v1695902182509!5m2!1ses!2sar" width="auto" height="auto" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-4 contenInfo">
                                <h4>Corrientes</h4>
                                <p>Medrano 1864, - Corrientes Capital - Corrientes</p>
                                <p>(0362) 411 5024</p>
                                <p>(0379) 579 0290</p>
                                <p>Lunes a Viernes de 8 a 17 hs</p>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-8 containerMap">
                                <iframe className="contenMap" title="Sucursal Corrientes" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3539.5253998280305!2d-58.8015715252348!3d-27.484031117315755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94456bafe5d438a7%3A0xe1ab78e74ebfc782!2sLeiten%20Corrientes!5e0!3m2!1ses!2sar!4v1695902110181!5m2!1ses!2sar" width="auto" height="auto" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>

                    <div className="linea"></div>

                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-4 contenInfo">
                                <h4>Mendoza</h4>
                                <p>Sobremonte 261, Guaymallén - Mendoza Capital - Mendoza</p>
                                <p>(0351) 563 6457</p>
                                <p>(011) 5263 8899</p>
                                <p>Lunes a Viernes de 8 a 17 hs</p>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-8 containerMap">
                                <iframe className="contenMap" title="Sucursal Mendoza" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3349.951185325358!2d-68.83425002496364!3d-32.89945886950575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x967e099bb8ade03b%3A0xebd8cf9d386e918d!2sLeiten%20Mendoza!5e0!3m2!1ses!2sar!4v1695901940257!5m2!1ses!2sar" width="auto" height="auto" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-4 contenInfo">
                                <h4>Neuquén</h4>
                                <p>Lulés 2520, - Neuquén Capital - Neuquén</p>
                                <p>(011) 5263 8899</p>
                                <p>(011) 5263 8899</p>
                                <p>Lunes a Viernes de 8 a 17 hs</p>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-8 containerMap">
                                <iframe className="contenMap" title="Sucursal Neuquén" src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2764.2074691615194!2d-68.02727861271974!3d-38.95322150979262!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sleiten%20neuquen!5e0!3m2!1ses!2sar!4v1695902237154!5m2!1ses!2sar" width="auto" height="auto" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        </StyledMaps>
    )
}

const StyledMaps = styled.div`
    .contenInfo{
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0rem;
        @media(min-width: 768px){
            margin-top: 0rem;
        }
    }
    .containerMap{
        text-align: center;
        @media(min-width: 768px){
            text-align: end;
        }
    }
    .contenMap{
        padding: 1rem;
        max-width: 100%;
        max-height: 100%;
    }
    iframe{
        padding: 1rem 0rem;
    }
    p{
        font-size: 0.9rem;
    }
`