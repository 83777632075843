import Styled from 'styled-components';
import Card from './Card';

export default function CardGrups(props){
    return(
        <StyledCardGroup>
            <div className='containerCard'>
                
                {props.listCard.map((data) => (
                    <Card key={data.id} CardInfo={data}/>
                ))}
                
            </div>
        </StyledCardGroup>
    )
}

const StyledCardGroup = Styled.div`
    .containerCard{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        
        @media (min-width: 768px){
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: 992px){
            grid-template-columns: repeat(3, 1fr);
        }
    }
`